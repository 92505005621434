<template>
	<div id="body" class="w-full h-screen">
		<Header/>
		<main>
			<Content name="printing"/>
			<Showcase/>
			<Testimonials/>
			<Quote/>
			<Footer/>
		</main>
	</div>
</template>

<script>
	import Header from '@/components/Header'
	import Content from '@/components/content/ContentService'
    import Showcase from '@/components/Showcase'
	import Testimonials from '@/components/testimonials/Testimonials'
	import Quote from '@/components/cta/Quote'
	import Footer from '@/components/Footer'

	export default {
		name: 'Printing',
		components: {
			Header,
			Content,
			Showcase,
			Testimonials,
			Quote,
			Footer,
		},
	}
</script>